import React from "react";
import {
  ArrowRight,
  DatabaseZap,
  Lightbulb,
  Combine,
  MousePointerSquareDashed,
} from "lucide-react";
import Stories from "./Stories";
const EngineeringServices = () => {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="bg-white  relative px-10">
        <div className="md:h-[100vh] relative flex flex-wrap md:flex-nowrap gap-24 py-24">
          <div className="w-full h-full md:w-6/12 flex flex-col gap-4 justify-center items-start md:py-24">
            <h1 className="text-7xl md:text-9xl  font-bold text-gray-300 ">
              01
            </h1>
            <h1 className="text-2xl md:text-5xl font-bold text-blue-600  ">
              Concept to Consumer
            </h1>
            <p className="text-sm md:text-sm text-gray-400 text-justify md:mt-4 ">
              Our Concept to Consumer Division is a life cycle engineering,
              product development, and supply chain management Team. It
              represents the entire journey a product takes from its initial
              concept or idea to reaching the end consumer. This concept is
              crucial for understanding the various stages and processes
              involved in bringing a product to market while considering its
              environmental and economic impacts.
            </p>
            <button className="px-4 py-2 bg-orange-400 text-white font-semibold flex gap-2 mt-4">
              Talk With US <ArrowRight />
            </button>
          </div>
          <div className="md:mt-6 flex gap-2  flex-wrap  w-full h-full items-center md:w-6/12 ">
            <div className="w-full">
              <div className="w-full flex gap-4">
                <div className="flex flex-col items-center gap-2 relative w-4/12  overflow-hidden ">
                  <div className="w-[52px] h-[52px] bg-[#FFC436] font-bold rounded-full text-white flex justify-center items-center">
                    1
                  </div>
                  <h1 className="text-sm font-bold text-gray-600 ">
                    Concept and Design
                  </h1>
                  <p className="text-xs  text-justify mt-1 text-gray-400 hidden md:block">
                    This is the initial stage where the idea for a product is
                    conceived. It involves market research, identifying consumer
                    needs, and developing a product concept.
                  </p>
                  <p className="text-xs  text-justify mt-1 text-gray-400 md:hidden">
                    This is the initial stage where the idea for a product is
                    conceived.
                  </p>
                </div>
                <div className="flex flex-col items-center gap-2 relative w-4/12    ">
                  <div className="w-[52px] h-[52px] bg-[#0174BE] font-bold rounded-full text-white flex justify-center items-center">
                    2
                  </div>
                  <h1 className="text-sm font-bold text-gray-600 ">
                    Development
                  </h1>
                  <p className="text-xs  text-justify mt-1 text-gray-400">
                    During this phase, the product design is refined, prototypes
                    are created, and the necessary technologies and materials
                    are selected.
                  </p>
                </div>
                <div className="flex flex-col items-center gap-2 relative w-4/12   ">
                  <div className="w-[52px] h-[52px] bg-[#0C356A] font-bold rounded-full text-white flex justify-center items-center">
                    3
                  </div>
                  <h1 className="text-sm font-bold text-gray-600 ">
                    Manufacturing
                  </h1>
                  <p className="text-xs  text-justify mt-1 text-gray-400">
                    Once the product design is finalized, it goes into mass
                    production. The manufacturing process can vary significantly
                    based on the type of product.
                  </p>
                </div>
              </div>
              <div className="w-full h-[2px] bg-gray-300 mt-8 mb-8"></div>
              <div className="w-full flex gap-4 ">
                <div className="flex flex-col items-center gap-2 w-4/12 ">
                  <div className="w-[52px] h-[52px] bg-[#FF9130] font-bold rounded-full text-white flex justify-center items-center">
                    4
                  </div>
                  <h1 className="text-sm font-bold text-gray-600 ">
                    Distribution
                  </h1>
                  <p className="text-xs  text-justify mt-1 text-gray-400">
                    After manufacturing, the product needs to be distributed to
                    various markets. This involves transportation, logistics,
                    and supply chain management to ensure products reach
                  </p>
                </div>
                <div className="flex flex-col items-center gap-2 w-4/12 ">
                  <div className="w-[52px] h-[52px] bg-[#FF6969] font-bold rounded-full text-white flex justify-center items-center">
                    5
                  </div>
                  <h1 className="text-sm font-bold text-gray-600 ">
                    Marketing and Sales
                  </h1>
                  <p className="text-xs  text-justify mt-1 text-gray-400">
                    In this phase, the product is introduced to consumers
                    through marketing campaigns, advertising, and sales efforts.
                    Pricing, promotion, and distribution channels are all part
                    of this stage.
                  </p>
                </div>
                <div className="flex flex-col items-center gap-2 w-4/12 ">
                  <div className="w-[52px] h-[52px] bg-[#141E46] font-bold rounded-full text-white flex justify-center items-center">
                    6
                  </div>
                  <h1 className="text-sm font-bold text-gray-600 ">
                    Consumer Use
                  </h1>
                  <p className="text-xs  text-justify mt-1 text-gray-400">
                    This is when the product is in the hands of consumers, and
                    it's being used as intended. The product's performance and
                    reliability are key factors during this stage, as they
                    influence consumer satisfaction.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="md:h-[100vh] relative flex flex-wrap md:flex-nowrap gap-4 flex-col-reverse md:flex-row"
          style={{
            backgroundImage: `url(${
              process.env.PUBLIC_URL + "/images/gray-triangles.svg"
            })`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        >
          <div className="w-full h-full md:w-6/12 flex  gap-4 justify-between items-start md:py-24">
            <Stories />
          </div>
          <div className="w-full h-full md:w-6/12 flex flex-col gap-4 justify-center items-start py-24">
            <h1 className="text-7xl md:text-9xl  font-bold text-gray-300 ">
              02
            </h1>
            <h1 className="text-2xl md:text-5xl font-bold text-blue-700 ">
              Robotic Systems
            </h1>
            <p className="text-sm md:text-sm text-gray-400 text-justify md:mt-2 pr-8 ">
              Robotic systems in the context of process engineering Division
              refer to the integration of robotic technology and automation into
              industrial processes to enhance efficiency, accuracy, and safety.
              These systems are used across various industries, including
              manufacturing, chemical processing, pharmaceuticals, and more.
            </p>
            <button className="px-4 py-2 bg-orange-400 text-white font-semibold flex gap-2">
              Talk With US <ArrowRight />
            </button>
          </div>
        </div>
        <div className="md:min-h-[100vh] relative  gap-24 py-24">
          <div
            className="w-full h-full flex flex-col gap-4 justify-center items-start md:py-24"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "/images/design-validation-bg.svg"
              })`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right",
              backgroundSize: "contain",
            }}
          >
            <h1 className="text-7xl md:text-9xl  font-bold text-gray-300 ">
              03
            </h1>
            <h1 className="text-2xl md:text-5xl font-bold text-blue-600  ">
              Design & Validation
            </h1>
            <p className="text-sm md:text-sm text-gray-400 text-justify md:mt-4 md:w-6/12  ">
              Division of Design and Validation involves in value engineering,
              "Design and Validation" are critical phases aimed at optimizing
              the design and ensuring that the final product or process delivers
              the desired value while minimizing costs. Value engineering, often
              abbreviated as VE, is a systematic approach to improve the value
              of a product or system through its life cycle. Here's an overview
              of the Design and Validation phases within value engineering.
            </p>
            <button className="px-4 py-2 bg-orange-400 text-white font-semibold flex gap-2 mt-4">
              Talk With US <ArrowRight />
            </button>
          </div>
          <div className="flex gap-12 md:flex-nowrap flex-wrap mt-8 md:mt-0">
            <div className="flex flex-col justify-start items-start gap-2 w-full md:w-3/12">
              <DatabaseZap className="text-blue-500" />
              <h1 className="font-bold text">Information Gathering</h1>
              <p className="text-sm text-gray-500 text-justify relative after:h-full before:w-[2px] before:bg-blue-500 before:absolute before:-left-2">
                In this initial step, a cross-functional team of experts, which
                may include engineers, designers, and cost analysts, gathers
                detailed information about the project, product, or process
                under consideration...
              </p>
            </div>
            <div className="flex flex-col justify-start items-start gap-2 w-full md:w-3/12">
              <Lightbulb className="text-yellow-400" />
              <h1 className="font-bold text">Idea Generation</h1>
              <p className="text-sm text-gray-500 text-justify relative before:h-full before:w-[2px] before:bg-yellow-400 before:absolute before:-left-2">
                The team brainstorm and generate a wide range of ideas and
                alternative design solutions. This creative process aims to
                identify potential areas for improvement in terms of cost,
                performance, and functionality.
              </p>
            </div>
            <div className="flex flex-col justify-start items-start gap-2 w-full md:w-3/12">
              <Combine className="text-red-400" />
              <h1 className="font-bold text">Evaluation of Alternatives</h1>
              <p className="text-sm text-gray-500 text-justify relative before:h-full before:w-[2px] before:bg-red-400 before:absolute before:-left-2">
                The team evaluates each alternative design, considering factors
                such as functionality, durability, ease of manufacturing, and
                operational efficiency. They also assess the impact of each
                design on the overall cost.
              </p>
            </div>
            <div className="flex flex-col justify-start items-start gap-2 w-full md:w-3/12 ">
              <MousePointerSquareDashed className="text-lime-400" />
              <h1 className="font-bold text">
                Selection of Best Value Design:
              </h1>

              <p className="text-sm text-gray-500 text-justify relative before:h-full before:w-[2px] before:bg-lime-400 before:absolute before:-left-2">
                Based on the evaluations, the team selects the design that
                offers the best value, considering a balance between cost and
                performance. This design is often a compromise between various
                design.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="flex justify-between items-center mb-32 relative h-[50vh] w-[full] after:top-0 after:right-0 after:absolute bg-red-400  after:bg-gray-700  after:w-[60vw] after:h-full md:after:-skew-x-[30deg] after:-skew-x-[54deg]  overflow-hidden after:origin-bottom-left"></div> */}
      </div>
    </div>
  );
};

export default EngineeringServices;
