import { ChevronLeft, Menu, X } from "lucide-react";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
const Nav = () => {
  const [hideNav, setHideNav] = useState(true);
  const [hideMenu, setHideMenu] = useState(false);
  return (
    <header className=" container px-12 py-6 flex items-center justify-between h-[56px] w-full text-gray-900  bg-white   shadow-md fixed  z-50">
      <NavLink
        to="/"
        className="font-bold text-primary text-xl md:hidden text-blue-500"
      >
        EMS
      </NavLink>
      <NavLink
        to="/"
        className="font-bold text-primary text-xl hidden md:inline-block  text-blue-500"
      >
        Elements Manufacturing{" "}
        <span className="text-sm font-normal text-gray-900">Solutions</span>
      </NavLink>
      <nav className="">
        <ul className="hidden md:flex items-center justify-center font-semibold">
          <li className="relative group px-3 py-2">
            <NavLink
              to="/"
              className="hover:opacity-50 cursor-default text-sm font-normal"
            >
              Home
            </NavLink>
          </li>
          <li className="relative group px-3 py-2">
            <button className="hover:opacity-50 cursor-default text-sm font-normal hover:text-primary ">
              Services
            </button>
            <div className="absolute top-2 -left-80 transition group-hover:translate-y-5 translate-y-0 opacity-0 invisible group-hover:opacity-100 group-hover:visible duration-500 ease-in-out group-hover:transform z-50 min-w-[560px] transform">
              <div className="relative top-6 p-6 bg-white rounded-xl shadow-xl w-full">
                <div className="w-10 h-10 bg-white transform rotate-45 absolute top-0 z-0 translate-x-0 transition-transform group-hover:translate-x-[20rem] duration-500 ease-in-out rounded-sm"></div>

                <div className="relative z-10">
                  <div className="grid grid-cols-2 gap-6">
                    <div>
                      <p className="uppercase tracking-wider text-gray-500 font-medium text-[13px]">
                        ENGINEERING SOLUTIONS
                      </p>
                      <ul className="mt-3 text-[15px]">
                        <li>
                          <NavLink
                            to="services/engineering"
                            className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-indigo-50 hover:to-pink-50 hover:via-blue-50 transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-indigo-600"
                          >
                            Concept to Consumer
                            <p className="text-gray-500 font-normal">
                              Life cycle engineering
                            </p>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="services/engineering"
                            className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-indigo-50 hover:to-pink-50 hover:via-blue-50 transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-indigo-600"
                          >
                            Robotic Systems
                            <p className="text-gray-500 font-normal">
                              Process Engineering
                            </p>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="services/engineering"
                            className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-indigo-50 hover:to-pink-50 hover:via-blue-50 transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-indigo-600"
                          >
                            Design and Validation
                            <p className="text-gray-500 font-normal">
                              Value Engineering
                            </p>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <p className="uppercase tracking-wider text-gray-500 font-medium text-[13px]">
                        MANUFACTURING SOLUTIONS
                      </p>
                      <ul className="mt-3 text-[15px]">
                        <li>
                          <NavLink
                            to="services/manufacturing"
                            className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-indigo-50 hover:to-pink-50 hover:via-blue-50 transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-indigo-600"
                          >
                            Product Development
                            <p className="text-gray-500 font-normal">
                              Contract manufacturing
                            </p>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="services/manufacturing"
                            className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-indigo-50 hover:to-pink-50 hover:via-blue-50 transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-indigo-600"
                          >
                            Fixtures and Equipments
                            <p className="text-gray-500 font-normal">
                              Optimization and Validation
                            </p>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to="services/manufacturing"
                            className="block p-2 -mx-2 rounded-lg hover:bg-gradient-to-br hover:from-indigo-50 hover:to-pink-50 hover:via-blue-50 transition ease-in-out duration-300 text-gray-800 font-semibold hover:text-indigo-600"
                          >
                            Technology Products
                            <p className="text-gray-500 font-normal">
                              Precision System integration
                            </p>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          {/* <li className="relative group px-3 py-2">
            <button className="hover:opacity-50 cursor-default text-sm font-normal hover:text-primary ">
              Products
            </button>
          </li>
          <li className="relative group px-3 py-2">
            <button className="hover:opacity-50 cursor-default text-sm font-normal hover:text-primary ">
              R&D
            </button>
          </li> */}
          {/* <li className="relative group px-3 py-2">
            <button className="hover:opacity-50 cursor-default text-sm font-normal hover:text-primary ">
              About
            </button>
          </li> */}
          <li className="relative group px-3 py-2">
            <NavLink
              to="/about"
              className="hover:opacity-50 cursor-default text-sm font-normal"
            >
              About
            </NavLink>
          </li>
          <li className="relative group px-3 py-2">
            <NavLink
              to="/contact"
              className="hover:opacity-50 cursor-default text-sm font-normal"
            >
              Contact
            </NavLink>
          </li>
        </ul>
        <button onClick={() => setHideNav(!hideNav)} className="md:hidden">
          <Menu />
        </button>

        <ul
          className={`md:hidden flex flex-col flex-wrap absolute top-0 p-4 left-0 w-full bg-white shadow-feature ${
            hideNav ? "hidden" : ""
          } `}
        >
          <div className="mb-2 flex justify-between">
            <div className="flex">
              <h1
                className={`text-2xl font-bold text-black ${
                  hideMenu ? "hidden" : ""
                }`}
              >
                EMS
              </h1>
              <button
                onClick={() => setHideMenu(false)}
                className={`flex justify-center items-center font-semibold text-xl -translate-x-2 ${
                  hideMenu ? "" : "hidden"
                }`}
              >
                <ChevronLeft />
                Back
              </button>
            </div>

            <button
              // onClick={() => {
              //   setHideNav(!hideNav), setHideMenu(false);
              // }}
              onClick={() => {
                setHideNav(!hideNav);
                setHideMenu(false);
              }}
            >
              <X />
            </button>
          </div>
          <div className={` ${hideMenu ? "hidden" : ""} flex flex-col gap-4`}>
            <NavLink
              to="/"
              className="hover:opacity-50 cursor-default text-xl font-normal"
              onClick={() => setHideNav(!hideNav)}
            >
              Home
            </NavLink>
            <li onClick={() => setHideMenu(true)} className="font-normal">
              Services
            </li>
            <li>
              <NavLink
                to="/about"
                className="font-normal"
                onClick={() => setHideNav(!hideNav)}
              >
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                className="font-normal"
                onClick={() => setHideNav(!hideNav)}
              >
                Contact
              </NavLink>
            </li>
          </div>
          <div
            className={` transition-transform delay-75 top-14 ${
              hideMenu
                ? "transform translate-x-0 delay-700"
                : "transform translate-x-full"
            } ${hideMenu ? "" : "hidden"}`}
          >
            <ul className="flex flex-col gap-4 items-start   mt-4 mb-4">
              <li>
                <NavLink
                  to="services/engineering"
                  className=" font-normal"
                  onClick={() => setHideNav(!hideNav)}
                >
                  Engineering Solutions
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="services/manufacturing"
                  className=" font-normal"
                  onClick={() => setHideNav(!hideNav)}
                >
                  Manufacturing Solutions
                </NavLink>
              </li>
            </ul>
          </div>
        </ul>
      </nav>
    </header>
  );
};

export default Nav;
