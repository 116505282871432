import React, { useState } from "react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const Clients = () => {
  const [clients, setClients] = useState([
    "/images/clients/client_1.png",
    "/images/clients/client_2.png",
    "/images/clients/client_3.png",
    "/images/clients/client_4.png",
    "/images/clients/client_5.png",
    "/images/clients/client_6.png",
  ]);
  return (
    <div className="h-[100px] w-full flex justify-center items-center pb-6">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2000,
          pauseOnMouseEnter: true,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        slidesPerView={3}
        loop={true}
        breakpoints={{
          0: {
            slidesPerView: 2,
          },
          400: {
            slidesPerView: 2,
          },
          639: {
            slidesPerView: 3,
          },
          865: {
            slidesPerView: 5,
          },
        }}
        className="px-0 w-full h-full"
      >
        {clients.map((client, index) => (
          <SwiperSlide className=" text-left w-[100px] h-[50px] flex flex-col gap-4 px-0">
            <div className="relative w-full h-full  flex justify-center items-center">
              <img src={client} alt="" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Clients;
