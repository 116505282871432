import React from "react";
import { ChevronUpCircle } from "lucide-react";
const Footer = () => {
  return (
    <footer className=" w-full bg-[#0052CC]  ">
      <div className="container p-24 text-white flex justify-center items-start flex-wrap w-full h-full">
        <div className="w-full md:w-2/12">
          <p>EMS</p>
        </div>
        <div className="w-full md:w-4/12">
          <div>
            <span className="font-medium">Registered office :</span>
            <h1 className="mt-2 mb-2 font-bold">
              Elements Manufacturing Solutions Private Limited
            </h1>
            <p className=" font-extralight">
              No.15/119, First Floor, Kanaka Complex, 80 ft Road, Srinivasa
              Nagar, Bengaluru – 560 050 .
            </p>
          </div>

          <div className="mt-8">
            <span className="font-medium">Factory :</span>
            <h1 className="mt-2 mb-2 font-bold">
              Elements Manufacturing Solutions Private Limited
            </h1>
            <p className=" font-extralight">
              No, 103/1, Road No. 4, Jigani Industrial Area, Jigani, Bengaluru,
              Karnataka 562106
            </p>
          </div>
        </div>
        <div className="w-full hidden md:w-4/12 md:flex flex-col gap-1 justify-center items-center font-extralight">
          <h1 className="cursor-pointer hover:font-bold">Home</h1>
          <h1 className="cursor-pointer hover:font-bold">Service</h1>
          <h1 className="cursor-pointer hover:font-bold">About</h1>
          <h1 className="cursor-pointer hover:font-bold">Contact</h1>
        </div>
        <div className="w-full hidden md:w-2/12 md:block">
          <ChevronUpCircle size={48} className="cursor-pointer" />
        </div>
        <div className="mt-20 font-extralight">
          © 2023 EMS. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
