import React from "react";
import Hero from "../components/landing/Hero";
import Stories from "../components/landing/Stories";
import Experties from "../components/landing/Experties";

const Home = () => {
  return (
    <section>
      <Hero />
      <section className="">
        <Stories />
      </section>
      <section>
        <Experties />
      </section>
      <section>
        <div className="flex justify-between items-center mb-32 relative md:h-[100vh] w-[full] md:after:top-0 md:after:right-0 md:after:absolute bg-white  md:after:bg-gray-50  md:after:w-[50vw] md:after:h-full md:md:after:-skew-x-[10deg] md:after:-skew-x-[54deg]  overflow-hidden md:after:origin-bottom-left">
          <div className="container">
            <div className="w-6/12">
              <img src="" alt="" />
            </div>
            <div className="w-6/12">
              <img src="" alt="" />
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Home;
