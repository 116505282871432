import React from "react";
import EngineeringHero from "../../components/services/engineering/Hero";
import EngineeringServices from "../../components/services/engineering/Services";

const EngineeringService = () => {
  return (
    <div>
      <EngineeringHero />
      <EngineeringServices />
    </div>
  );
};

export default EngineeringService;
