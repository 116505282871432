import React, { useState } from "react";
import {
  ArrowRight,
  Search,
  PencilRuler,
  Atom,
  RadioReceiver,
  ScanText,
  TrendingUp,
  ShieldCheck,
  Route,
  FlaskConical,
  Scaling,
  Truck,
  Subscript,
  Zap,
} from "lucide-react";
import Stories from "./Stories";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/autoplay";
import SwiperCore from "swiper";
const ManufacturingServices = () => {
  const [fixtures, setFixtures] = useState([
    {
      icon: <Search className="text-blue-400" />,
      title: "Identify Requirements",
      description:
        "The optimization process begins with a clear understanding of the requirements and objectives. This includes understanding the purpose, operational specifications, and performance criteria of the fixtures and equipment",
    },
    {
      icon: <PencilRuler className="text-red-400" />,
      title: "Design and Configuration",
      description:
        "Optimal design and configuration are essential. This may involve designing fixtures and equipment to be versatile, easily adjustable, and compatible with a range of tasks. It should also consider safety, ergonomics, and ease of use.",
    },
    {
      icon: <Atom className="text-lime-400" />,
      title: "Materials and Components",
      description:
        "The optimization process aims to enhance efficiency and productivity. This may involve improving automation, reducing energy consumption, or streamlining processes to minimize downtime.",
    },
    {
      icon: <Zap className="text-purple-400" />,
      title: "Efficiency and Productivity",
      description:
        "Selecting the right materials and components is crucial. This includes considering the durability, wear resistance, and suitability of materials for the intended use. For equipment, selecting the appropriate components, such as sensors and motors, is vital.",
    },
    {
      icon: <TrendingUp className="text-green-400" />,
      title: "Cost-Effectiveness",
      description:
        "Selecting the right materials and components is crucial. This includes considering the durability, wear resistance, and suitability of materials for the intended use. For equipment, selecting the appropriate components, such as sensors and motors, is vital.",
    },
    {
      icon: <FlaskConical className="text-sky-400" />,
      title: "Testing and Prototyping",
      description:
        " Building prototypes and conducting testing is a critical step. This helps validate that the optimized design and configuration meet performance criteria. Any necessary adjustments can be made based on test results.",
    },
  ]);

  const [fixturesValidation, setFixturesValidation] = useState([
    {
      icon: <Search className="text-blue-400" />,
      title: "Performance Testing",
      description:
        "Validation includes rigorous performance testing. This ensures that fixtures and equipment meet the intended specifications and perform as required. Testing may include load testing, stress testing, and endurance testing.",
    },
    {
      icon: <PencilRuler className="text-red-400" />,
      title: "Regulatory Compliance",
      description:
        "Many industries have regulatory standards and safety requirements. Equipment must be validated to ensure compliance with these standards. Validation may require documentation and certification processes to demonstrate compliance.",
    },
    {
      icon: <Atom className="text-lime-400" />,
      title: "Calibration and Accuracy",
      description:
        "Precision equipment and fixtures often require calibration and validation of accuracy. This may involve comparing measurements or readings against known standards and adjusting as necessary.",
    },
    {
      icon: <Zap className="text-purple-400" />,
      title: "Reliability Testing",
      description:
        "Equipment and fixtures should be validated for reliability over time. This includes assessing their performance under various conditions, such as temperature variations and environmental factors.",
    },
    {
      icon: <TrendingUp className="text-green-400" />,
      title: "Operational Validation",
      description:
        "Ensure that equipment and fixtures operate safely and effectively within the context of their intended use. This may involve testing under normal operating conditions and assessing safety features and emergency protocols.",
    },
    {
      icon: <FlaskConical className="text-sky-400" />,
      title: "Documentation and Records",
      description:
        " Proper documentation of the validation process is essential. This includes recording test results, compliance with regulations, and any adjustments or modifications made during validation.",
    },
  ]);

  return (
    <div>
      <div className="w-full min-h-min md:h-full flex  justify-center flex-wrap items-center bg-white">
        <div className="relative container  w-full">
          <div className="w-full md:h-[100vh] relative flex flex-wrap md:flex-nowrap gap-36 md:gap-24 py-24 ">
            <div className="w-full h-full md:w-6/12 flex flex-col gap-4 justify-center items-start md:py-24">
              <h1 className="text-2xl md:text-5xl font-bold text-blue-600  ">
                Product Development
              </h1>
              <p className="text-sm md:text-sm text-gray-400 text-justify md:mt-4 ">
                Our Concept to Consumer Division is a life cycle engineering,
                product development, and supply chain management Team. It
                represents the entire journey a product takes from its initial
                concept or idea to reaching the end consumer. This concept is
                crucial for understanding the various stages and processes
                involved in bringing a product to market while considering its
                environmental and economic impacts.
              </p>
              <button className="px-4 py-2 bg-orange-400 text-white font-semibold flex gap-2 mt-4">
                Talk With US <ArrowRight />
              </button>
            </div>
            <div className=" flex gap-2  flex-wrap  w-full h-full items-center md:w-6/12 relative before:w-[200px] before:absolute before:h-full before:bg-yellow-200 before:-skew-x-12">
              <img
                src="/images/services/manufacturing/product-development.jpg"
                alt=""
                className="absolute "
              />
            </div>
          </div>
        </div>
        <div className="py-2 container">
          <h1 className="text-left font-bold text-3xl text-gray-500 mb-2 mt-12 md:mt-0 md:mb-8">
            Process
          </h1>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 5000,
              pauseOnMouseEnter: true,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            slidesPerView={3}
            loop={true}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              400: {
                slidesPerView: 2,
              },
              639: {
                slidesPerView: 3,
              },
              865: {
                slidesPerView: 5,
              },
            }}
          >
            <SwiperSlide className="shadow-card p-4 text-left min-h-[250px] flex flex-col gap-4">
              <h1 className="flex font font-bold text-3xl gap-2 items-center mb-2 text-orange-400">
                1{/* <PencilRuler /> */}
              </h1>
              <h1 className="text-base font-bold">
                Product Concept and Design
              </h1>
              <p className="text-xs mt-2 text-gray-500">
                <span className="font-bold text-gray-600">
                  Idea Generation:
                </span>{" "}
                The product development process starts with generating a
                concept..
              </p>
              <p className="text-xs mt-2 text-gray-500">
                <span className="font-bold text-gray-600">
                  Design and Engineering
                </span>{" "}
                Once the concept is established, the product design ...
              </p>
            </SwiperSlide>
            <SwiperSlide className="shadow-card p-4 text-left min-h-[250px] flex flex-col gap-4">
              <h1 className="flex font font-bold text-3xl gap-2 items-center mb-2 text-blue-400">
                2{/* <PencilRuler /> */}
              </h1>
              <h1 className="text-base font-bold">Material Selection</h1>
              <p className="text-xs mt-2 text-gray-500">
                <span className="font-bold text-gray-600">
                  Material Sourcing:
                </span>
                The choice of materials is a critical aspect of product
                development. The hiring company may provide specific material
                requirements, or the contract manufacturer may source materials
                based on the agreed-upon specifications and quality standards.
              </p>
            </SwiperSlide>
            <SwiperSlide className="shadow-card p-4 text-left min-h-[250px] flex flex-col gap-4">
              <h1 className="flex font font-bold text-3xl gap-2 items-center mb-2 text-lime-400">
                3
              </h1>
              <h1 className="text-base font-bold">Prototype Development</h1>
              <p className="text-xs mt-2 text-gray-500">
                <span className="font-bold text-gray-600">Prototyping:</span> A
                prototype of the product is typically developed to validate the
                design and functionality. The contract manufacturer may be
                responsible for creating these prototypes or collaborating with
                the hiring company in the prototyping process.
              </p>
            </SwiperSlide>
            <SwiperSlide className="shadow-card p-4 text-left min-h-[250px] flex flex-col gap-4">
              <h1 className="flex font font-bold text-3xl gap-2 items-center mb-2 text-yellow-400">
                4
              </h1>
              <h1 className="text-base font-bold">
                Manufacturability Assessment
              </h1>
              <p className="text-xs mt-2 text-gray-500">
                <span className="font-bold text-gray-600">
                  Design for Manufacturability (DFM):
                </span>{" "}
                The contract manufacturer assesses the product design for
                manufacturability. This includes evaluating whether the design
                is optimized for efficient and cost-effective production.
              </p>
            </SwiperSlide>
            <SwiperSlide className="shadow-card p-4 text-left min-h-[250px] flex flex-col gap-4">
              <h1 className="flex font font-bold text-3xl gap-2 items-center mb-2 text-red-400">
                5
              </h1>
              <h1 className="text-base font-bold">Cost Estimation</h1>
              <p className="text-xs mt-2 text-justify text-gray-500">
                <span className="font-bold text-gray-600">Cost Analysis:</span>{" "}
                The contract manufacturer provides a cost estimate for
                manufacturing the product. This includes labor costs, material
                costs, overhead, and any other associated expenses. The hiring
                company reviews and negotiates the costs.
              </p>
            </SwiperSlide>
            <SwiperSlide className="shadow-card p-4 text-left min-h-[250px] flex flex-col gap-4">
              <h1 className="flex font font-bold text-3xl gap-2 items-center mb-2 text-green-400">
                6
              </h1>
              <h1 className="text-base font-bold">
                Regulatory and Quality Compliance
              </h1>
              <p className="text-xs mt-2 text-justify text-gray-500">
                <span className="font-bold text-gray-600">
                  Compliance Requirements:
                </span>{" "}
                If the product is subject to regulatory or quality standards,
                the contract manufacturer works to ensure compliance with these
                requirements. This may involve certification processes and
                quality.
              </p>
            </SwiperSlide>
            <SwiperSlide className="shadow-card p-4 text-left min-h-[250px] flex flex-col gap-4">
              <h1 className="flex font font-bold text-3xl gap-2 items-center mb-2 text-blue-400">
                7
              </h1>
              <h1 className="text-base font-bold">Production Planning</h1>
              <p className="text-xs mt-2 text-justify text-gray-500">
                <span className="font-bold text-gray-600">
                  Production Setup:
                </span>{" "}
                Once the design is finalized, production planning begins. The
                contract manufacturer establishes production processes,
                including tooling, equipment, and assembly lines, as necessary.
              </p>
            </SwiperSlide>
            <SwiperSlide className="shadow-card p-4 text-left min-h-[250px] flex flex-col gap-4">
              <h1 className="flex font font-bold text-3xl gap-2 items-center mb-2 text-lime-400">
                8
              </h1>
              <h1 className="text-base font-bold">Testing and Validation</h1>
              <p className="text-xs mt-2 text-justify text-gray-500">
                <span className="font-bold text-gray-600">
                  Quality Control:
                </span>{" "}
                Throughout the manufacturing process, the contract manufacturer
                conducts quality control and testing to ensure that each unit of
                the product meets specified standards.
              </p>
            </SwiperSlide>
            <SwiperSlide className="shadow-card p-4 text-left min-h-[250px] flex flex-col gap-4">
              <h1 className="flex font font-bold text-3xl gap-2 items-center mb-2 text-pink-400">
                9
              </h1>
              <h1 className="text-base font-bold">Scaling Production</h1>
              <p className="text-xs mt-2 text-justify text-gray-500">
                <span className="font-bold text-gray-600">
                  Ramp-Up and Scaling:
                </span>{" "}
                As production progresses, the contract manufacturer scales up
                the manufacturing process to meet the required production
                volumes. This may involve adjustments and optimizations for
                increased efficiency.
              </p>
            </SwiperSlide>
            <SwiperSlide className="shadow-card p-4 text-left min-h-[250px] flex flex-col gap-4">
              <h1 className="flex font font-bold text-3xl gap-2 items-center mb-2 text-sky-400">
                10
              </h1>
              <h1 className="text-base font-bold">Delivery and Logistics</h1>
              <p className="text-xs mt-2 text-justify text-gray-500">
                <span className="font-bold text-gray-600">
                  Packaging and Shipping:
                </span>{" "}
                The contract manufacturer may handle packaging and shipping of
                the final products to distribution centers or directly to
                customers, depending on the terms of the contract.
              </p>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div className="mb-12">
        <div className="w-full min-h-min md:h-full flex  justify-center flex-wrap items-center bg-white container">
          <div className="w-full md:h-[100vh] relative flex flex-wrap md:flex-nowrap gap-36 md:gap-24 py-24 ">
            <div className="w-full h-full md:w-6/12 flex flex-col gap-4 justify-center items-start md:py-24">
              <h1 className="text-2xl md:text-5xl font-bold text-blue-600  ">
                Fixtures & Equipment's
              </h1>
              <p className="text-sm md:text-sm text-gray-400 text-justify md:mt-4 ">
                Our Fixtures and equipment Division play a crucial role in
                various industries, including manufacturing, research, and
                laboratory settings. Optimization and validation of fixtures and
                equipment are essential processes to ensure their efficiency,
                reliability, and compliance with standards and specifications.
                Here's an overview of process followed by us to achieve positive
                outcomes
              </p>
              <button className="px-4 py-2 bg-orange-400 text-white font-semibold flex gap-2 mt-4">
                Talk With US <ArrowRight />
              </button>
            </div>
            <div className=" flex gap-2  flex-wrap  w-full h-full items-center md:w-6/12 relative before:w-[200px] before:absolute before:h-full before:bg-slate-200 before:-skew-x-12">
              <img
                src="/images/services/manufacturing/fixture.png"
                alt=""
                className="absolute "
              />
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col-reverse md:flex-row md:flex-wrap justify-center items-center flex-nowrap mt-8 md:mt-0 container">
          <div className="w-full md:w-6/12 flex  flex-wrap justify-center md:justify-start gap-8 mt-12 md:mt-0">
            {fixtures.map((fixture, index) => (
              <div
                className="flex flex-col gap-2 w-5/12 md:w-3/12 justify-start items-start text-start"
                key={index}
              >
                {fixture.icon}
                <h1 className="text-base font-bold text-gray-600">
                  {fixture.title}
                </h1>
                <p className="text-xs text-gray-400 text-justify max-h-[100px] overflow-hidden text-ellipsis  ">
                  {fixture.description}
                </p>
              </div>
            ))}
          </div>
          <div className="w-full md:w-6/12 flex justify-center items-center flex-wrap  mt-12 md:mt-0">
            <h1 className="text-2xl md:text-5xl font-bold text-gray-600  text-justify  ">
              Optimization of Fixtures and Equipment
            </h1>
          </div>
        </div>
        <div className="w-full flex flex-col-reverse md:flex-row-reverse md:flex-wrap justify-center items-center flex-nowrap mt-12 md:mt-36 container">
          <div className="w-full md:w-6/12 flex  flex-wrap justify-center md:justify-start gap-4 mt-12 md:mt-0">
            {fixturesValidation.map((fixture, index) => (
              <div
                className="flex flex-col gap-2 w-5/12 md:w-3/12 justify-start items-start text-start"
                key={index}
              >
                {fixture.icon}
                <h1 className="text-base font-bold text-gray-600">
                  {fixture.title}
                </h1>
                <p className="text-xs text-gray-400 text-justify max-h-[100px] overflow-hidden text-ellipsis  ">
                  {fixture.description}
                </p>
              </div>
            ))}
          </div>
          <div className="w-full md:w-6/12 flex justify-center items-center flex-wrap  mt-12 md:mt-0">
            <h1 className="text-2xl md:text-5xl font-bold text-gray-600 text-justify pr-24 ">
              Validation of Fixtures and Equipment
            </h1>
          </div>
        </div>
        {/* <div className="mt-12 flex justify-between items-center mb-32 relative h-[50vh] w-[full] after:top-0 after:right-0 after:absolute bg-red-400  after:bg-gray-700  after:w-[60vw] after:h-full md:after:-skew-x-[30deg] after:-skew-x-[54deg]  overflow-hidden after:origin-bottom-left"></div> */}
      </div>
    </div>
  );
};

export default ManufacturingServices;
