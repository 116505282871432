import React from "react";
import type { StoryType } from "../../types";
import Clients from "./Clients";

const Story: React.FC<StoryType> = ({
  background,
  description,
  href,
  title,
  className,
}) => {
  return (
    <div className="w-full md:w-4/12 bg-gradient-to-r from-[#0081CF] via-[#0089BA] to-[#008E9B] shadow-card">
      <div className="relative aspect-video w-full flex-shrink-0 border border-light-300 bg-light-300 dark:border-0 dark:bg-dark-500 xs:w-32">
        <h1 className="font-sans font-extrabold absolute text-2xl text-white top-4 left-4 z-10">
          {title}
        </h1>
        <img alt={title} src={background} className="aspect-video" />
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
      </div>
      <div>
        <p className="font-extralight p-4 text-white/90 text-ellipsis text-sm">
          {description}
        </p>
      </div>
      <div className="flex w-full p-4 justify-end">
        <button className="bg-white hover:bg-white/90 px-2 py-1 hover:text-primary text-primary/90 rounded-none font-semibold shadow-sm">
          Know more
        </button>
      </div>
    </div>
  );
};

const Stories = () => {
  const stories: StoryType[] = [
    {
      title: "Who we are",
      background: "/images/story/who_we_are.jpg ",
      description:
        "EMS is fast growing independent research and technology startup, with expertise in manufacturing and engineering processes...",
      href: "link to what we do",
      className: "",
    },
    {
      title: "What we do",
      background: "/images/story/what_we_do.jpg",
      description:
        "EMS provides engineering and manufacturing consultancy to with impartial expert advice, know how and safety...",
      href: "link to what we do",
      className: "",
    },
    {
      title: "Our Engements",
      background: "/images/story/our_engements.webp",
      description:
        "Our engagements encapsulate a diverse range of projects and services aimed at meeting the unique needs of our clients across",
      href: "link to what we do",
      className: "",
    },
  ];
  return (
    <div className="">
      <div className=" container flex justify-center items-center">
        <div className="w-full md:w-10/12 flex justify-center items-start gap-4 mb-10 mt-10 flex-wrap md:flex-nowrap">
          {stories.map((story, index) => (
            <Story {...story} key={index} />
          ))}
        </div>
      </div>
      <Clients />
    </div>
  );
};

export default Stories;
