import React from "react";

const ManufacturingHero = () => {
  return (
    <section className="w-[100vw] mt-[100px] md:mt-0  md:h-[100vh] flex justify-between items-center  relative bg-gray-50">
      <div className="container flex-wrap flex w-full h-full justify-center items-center gap-10 md:gap-0 ">
        <div className="w-full h-full md:w-6/12 flex flex-col items-start justify-center gap-10 ">
          <h1 className="text-4xl md:text-5xl font-bold text-blue-600">
            Manufacturing Services
          </h1>
          <p className="text-gray-600 text-justify">
            As a startup, we bring fresh perspectives to the table. We
            specialize in delivering agile and dynamic manufacturing solutions
            that optimize production processes, reduce costs, and enhance
            quality. From digital manufacturing and smart factories to supply
            chain optimization, we're ready to transform your operations.
          </p>
          <button className="bg-blue-600 px-4 py-2 text-white">Explore</button>
        </div>

        <div className="w-full  md:w-6/12 flex relative overflow-hidden  justify-center items-center md:before:block before:w-full  before:h-full before:hidden  before:absolute before:bg-gray-50 before:-skew-y-[80deg] before:origin-bottom-left     md:after:block after:top-0 after:right-0 after:w-full  after:h-full after:hidden  after:absolute after:bg-gray-50 after:-skew-y-[80deg] after:origin-top-right">
          <img
            src={
              process.env.PUBLIC_URL +
              "/images/services/manufacturing/plan-to-consumer.jpg"
            }
            alt=""
            className="md:h-[550px]"
          />
        </div>
      </div>
    </section>
  );
};

export default ManufacturingHero;
