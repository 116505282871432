import React from "react";

const Dash = () => {
  return (
    <div className="flex  gap-1 ">
      <div className="bg-blue-800 w-8 h-1 rounded-full"></div>
      <div className="bg-blue-800 w-5 h-1 rounded-full"></div>
      <div className="bg-blue-800 w-2 h-1 rounded-full"></div>
    </div>
  );
};

const About = () => {
  return (
    <div className=" bg-white  w-full overflow-x-hidden ">
      <div className="flex justify-center flex-col items-start w-full h-[100vh] relative container ">
        <div className="absolute bottom-0 -right-4 w-72 h-72 bg-purple-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
        <div className="absolute bottom-0 -right-12 w-72 h-72 bg-yellow-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
        <div className="absolute -bottom-8 right-20 w-72 h-72 bg-pink-300 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>

        <h1 className="text-4xl md:text-7xl font-thin text-gray-800">
          We believe that empathy,{" "}
        </h1>
        <h1 className="text-4xl md:text-7xl font-thin text-gray-800 mt-4">
          intuitive design and technology{" "}
        </h1>
        <h1 className="text-4xl md:text-7xl font-thin text-gray-800 mt-4">
          push us all forward.
        </h1>
      </div>
      <section className="  md:px-0 py-28 md:py-48  justify-center items-start bg-[#626666] overflow-visible">
        <div className="container py-2 flex flex-col gap-10">
          <h1 className="text-6xl md:text-9xl font-extrabold font-para bg-clip-text  bg-gradient-to-r from-[#7F00FF] to-pink-600 text-transparent   ">
            commit.
          </h1>
          <h1 className="text-6xl md:text-9xl font-extrabold font-para bg-clip-text  bg-gradient-to-r from-pink-600 to-[#7F00FF] text-transparent  ">
            explore.
          </h1>
          <h1 className="text-6xl md:text-9xl font-extrabold font-para bg-clip-text  bg-gradient-to-r from-[#7F00FF] to-pink-600 text-transparent  ">
            do great work.
          </h1>
        </div>
      </section>
      <section className="w-full ">
        <div className="container flex flex-wrap md:flex-row flex-col gap-4 justify-center py-20 md:py-28 items-center">
          <div className="md:w-3/12 w-full text-left ">
            <Dash />
            <h1 className="font-para font-semibold text-[#818385] text-lg mt-4 ">
              Who we are
            </h1>
          </div>
          <div className="md:w-3/12 w-full  font-para  text-[#626666] leading-8">
            <p>
              Elements Manufacturing Solutions pvt ltd is a Technology company
              located in Bengaluru, India, that is made up of strategists,
              designers, developers, and project managers that are forever
              curious and ready to transform the way business is done.
            </p>
          </div>
        </div>
        <div className="container flex flex-wrap md:flex-row flex-col gap-8 md:gap-4 justify-center py-8 md:py-28 items-center bg-slate-50">
          <div className="md:w-3/12 w-full ">
            <div className="relative aspect-[3.5/8] md:aspect-auto w-full min-h-[400px] md:-translate-x-32 shadow-feature">
              <img
                src={process.env.PUBLIC_URL + "images/about/who-we-are.jpg"}
                alt=""
                className="absolute shadow-feature "
              />
            </div>
          </div>
          <div className="md:w-3/12 w-full font-para  text-[#626666] leading-8">
            <h1 className="text-lg mb-8 font-semibold text-[#1d1f1f]">
              OUR PEOPLE COME FIRST
            </h1>
            <p>
              Although we are a well-oiled machine, our people are far from
              cogs. The talent we cultivate gets the importance of honing their
              respective crafts. It helps to better serve both each other and
              our partners, and it shows in everything – from what we design and
              produce, to what we value and believe.
            </p>
          </div>
        </div>
      </section>

      <section className="w-full py-20 md:py-40">
        <div className="container flex justify-center items-center flex-wrap gap-8">
          <div className="w-full md:w-4/12 font-para text-[#626666] leading-8 ">
            <h1 className="text-lg mb-8 font-semibold text-[#1d1f1f]">
              WHAT’S IN THE BOX!?
            </h1>
            <p>
              Doesn’t matter. We’re too busy thinking far outside of the
              constraints of the typical brief. Creativity: it’s what drives us.
              We do our best work when the guardrails are down and we can spread
              our wings. Designs are cleaner, strategies sharper, and the world
              generally becomes a better place.
            </p>
          </div>
          <div className="w-full md:w-5/12">
            <div className="relative aspect-video   w-full min-h-[400px] md:translate-x-32 shadow-feature">
              <img
                src={process.env.PUBLIC_URL + "/images/about/happyhour.jpg"}
                alt=""
                className="absolute  aspect-video shadow-feature"
              />
            </div>
          </div>
        </div>
        <div className="container flex flex-col-reverse md:flex-row justify-center items-center mt-12 md:mt-40 flex-wrap gap-8">
          <div className="w-full md:w-5/12 h-full">
            <div className="relative aspect-video   w-full min-h-[400px] md:-translate-x-32 shadow-feature ">
              <img
                src={
                  process.env.PUBLIC_URL + "/images/about/city-beautiful.jpg"
                }
                alt=""
                className="absolute  aspect-video shadow-feature"
              />
            </div>
          </div>
          <div className="w-full md:w-4/12 font-para  text-[#626666] leading-8">
            <h1 className="text-lg mb-8 font-semibold text-[#1d1f1f]">
              THE CITY BEAUTIFUL, AND BEYOND.
            </h1>
            <p>
              Although we call Downtown Bengaluru ’home’, we work well with
              partners from here to The Eiffel Tower. We also believe that great
              products can come from anywhere. So, we encourage our team to
              untether, stretch their legs, and explore alternative workspaces.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
