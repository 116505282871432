import React from "react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/autoplay";

const Hero = () => {
  return (
    <div className="md:h-[100vh] h-[420px] w-full flex justify-center items-center mt-10 aspect-video">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          pauseOnMouseEnter: true,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        slidesPerView={3}
        // loop={true}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          400: {
            slidesPerView: 1,
          },
          639: {
            slidesPerView: 1,
          },
          865: {
            slidesPerView: 1,
          },
        }}
        className="px-0 w-full h-full"
      >
        <SwiperSlide className="shadow-card p-4 text-left w-full h-full flex flex-col gap-4 px-0">
          <div className="relative min-h-screen flex justify-end items-center">
            <video
              className="absolute top-0 left-0 h-[360px] w-full md:h-full aspect-video object-cover -z-10"
              autoPlay
              muted
              loop
            >
              <source src="/videos/manufacturing.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="absolute text-white max-w-[600px] md:top-[30%] top-[5%] left-[5%] ">
              <h1 className="text-3xl md:text-4xl font-bold text-blue-500">
                Our Manufacturing solutions
              </h1>
              <p className="text-gray-50 p-4 ">
                We provide businesses with the flexibility and expertise to
                outsource production, allowing them to focus on their core
                strengths and achieve cost-effective, high-quality results
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="shadow-card p-4 text-left w-full h-full flex flex-col gap-4 px-0">
          <div className="relative min-h-screen flex justify-end items-center">
            <video
              className="absolute top-0 left-0 w-full h-full object-cover -z-10"
              autoPlay
              muted
              loop
            >
              <source src="/videos/aerospace.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="absolute text-white max-w-[600px] md:top-[30%] top-[5%] left-[5%] ">
              <h1 className="text-3xl md:text-4xl font-bold text-blue-500">
                Aerospace
              </h1>
              <p className="text-gray-50 p-4">
                Our MRO services ensure the safety and longevity of aircraft by
                providing maintenance, repair, and overhaul solutions to keep
                them in optimal condition
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="shadow-card p-4 text-left w-full h-full flex flex-col gap-4 px-0">
          <div className="relative min-h-screen flex justify-end items-center">
            <video
              className="absolute top-0 left-0 w-full h-full object-cover -z-10"
              autoPlay
              muted
              loop
            >
              <source src="/videos/automation.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>

            <div className="absolute text-white max-w-[600px] md:top-[30%] top-[5%] left-[5%] ">
              <h1 className="text-3xl md:text-4xl font-bold text-blue-500">
                Our Industrial automation
              </h1>
              <p className="text-gray-50 p-4">
                We enhances efficiency and productivity by using technology and
                control systems to streamline and optimize manufacturing and
                operational processes.
              </p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Hero;
