import React from "react";
import ManufacturingHero from "../../components/services/manufacturing/Hero";
import ManufacturingServices from "../../components/services/manufacturing/Services";

const ManufacturingService = () => {
  return (
    <div>
      <ManufacturingHero />
      <ManufacturingServices />
    </div>
  );
};

export default ManufacturingService;
