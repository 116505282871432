import React from "react";
import { Mail, MapPin } from "lucide-react";

function Contact() {
  return (
    <div className="mt-50 relative">
      <iframe
        src="https://www.google.com/maps/d/u/0/embed?mid=1FylVCgEsFvdZq2SLJWs-kZ7STgHZfiA&ehbc=2E312F"
        className="-mt-[2px] w-full h-screen"
      ></iframe>
      <div className="bg-blue-600  rounded-sm  md:w-[500px] md:h-[450px] bg-primary absolute top-20  md:top-[20%] md:right-10 p-4 flex flex-wrap flex-col ">
        <div className="w-full flex justify-start items-center gap-4">
          <Mail
            stroke="white"
            className="bg-white/10  w-12 h-12 p-2 rounded-md "
          />
          <h1 className="text-white font-light">operations@emsln.com</h1>
        </div>
        <div className="w-full h-[1px] bg-blue-400 mt-4"></div>
        <div className="mt-4 w-full flex-wrap flex flex-row py-12">
          <div className="w-6/12 flex flex-wrap flex-row ">
            <div className="w-2/12">
              <MapPin
                stroke="white"
                className="bg-white/10  w-8 h-8 p-2 rounded-full "
              />
            </div>
            <div className="w-10/12 flex flex-col gap4">
              <h1 className="font-semibold text-white/90">EMS Office</h1>
              <div className="text-white/80 text-sm mt-4">
                No.15/119, First Floor, Kanaka Complex, 80 ft Road, Srinivasa
                Nagar, Bengaluru – 560 050 .
              </div>
              <h1 className="text-white/90 font-semibold mt-3">
                +91-9481470589
              </h1>
            </div>
          </div>
          <div className="w-6/12 flex flex-wrap flex-row ">
            <div className="w-2/12">
              <MapPin
                stroke="white"
                className="bg-white/10  w-8 h-8 p-2 rounded-full "
              />
            </div>
            <div className="w-10/12 flex flex-col gap4">
              <h1 className="font-semibold text-white/90">EMS Factory</h1>
              <div className="text-white/80 text-sm mt-4 ">
                No, 103/1, Road No. 4, Jigani Industrial Area, Jigani,
                Bengaluru, Karnataka 562106
              </div>
              <h1 className="text-white/90 font-semibold mt-3">
                +91-9900827125
              </h1>
            </div>
          </div>
        </div>
        <div className="w-full h-[1px] bg-blue-400 mt-4"></div>
      </div>
    </div>
  );
}

export default Contact;
