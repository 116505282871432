import React from "react";
import { Boxes, Cpu } from "lucide-react";
const Experties = () => {
  return (
    <div>
      <div
        className="py-16 min-h-screen  md:h-screen  bg-no-repeat bg-cover"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "/images/experties/bg.png"
          })`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <div className="container flex justify-center mb-12 md:mb-0 md:justify-start">
          <h1 className="text-white text-4xl md:text-5xl font-bold">
            Our Expertise
          </h1>
        </div>
        <div className="h-full w-full">
          <div className="container w-full h-full flex flex-wrap justify-center items-center py-4">
            <div className="md:w-6/12 h-[400px]">
              <img
                src={
                  process.env.PUBLIC_URL + "/images/experties/engineering.png"
                }
                className="w-auto h-[350px] md:max-w-[40vw]"
              />
              {/* <Product /> */}
            </div>
            <div className="md:w-6/12 flex w-full justify-center item flex-wrap">
              <h1 className="text-4xl font-bold text-white">
                Engineering Solution
              </h1>
              <p className="text-center text-white mt-6 font-extralight">
                Our engineering prowess spans a wide range of industries. We
                excel in conceptualizing, designing, and implementing
                engineering processes that are efficient, reliable, and tailored
                to meet your unique needs.
              </p>

              <button className=" px-4 py-2  mt-12 rounded-none text-white bg-[#CC7A00] hover text-white:bg-[#CC7A00]">
                Know More
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full min-h-screen flex flex-wrap justify-center items-center  ">
        <div className="container py-10 md:py-12">
          <div className="w-full flex justify-center">
            <h1 className="text-3xl md:text-4xl font-bold text-blue-600 ">
              Our Engineering Services
            </h1>
          </div>
          <section className="w-full flex justify-center">
            <div className="w-full  flex-wrap md:flex-nowrap flex justify-center items-center mt-24 gap-4 ">
              <div className="w-full md:w-4/12 bg-white min-h-[300px] p-8 flex flex-col items-center gap-4 shadow-card">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services/engineering/desing.png"
                  }
                  className=" h-[42px] w-[42px]"
                />
                <h1 className="text-2xl text-primary font-bold mt-2">
                  Concept to Consumer
                </h1>
                <p className="text-sm text-center text-[#5C5C5C]">
                  Our "Concept to Consumer" Division is a life cycle
                  engineering, product development, and supply chain management
                  Team.
                </p>
                <button className=" px-4 py-2  mt-4 rounded-sm border border-red-300 ">
                  Know More
                </button>
              </div>
              <div className="w-full md:w-4/12 bg-white min-h-[300px] p-8 flex flex-col items-center gap-4 shadow-card">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services/engineering/manufacture.png"
                  }
                  className=" h-[42px] w-[42px]"
                />
                <h1 className="text-2xl text-primary font-bold mt-2">
                  Robotic systems
                </h1>
                <p className="text-sm text-center text-[#5C5C5C]">
                  Robotic systems in the context of process engineering Division
                  refer to the integration of robotic technology and automation
                </p>
                <button className=" px-4 py-2  mt-4 rounded-sm border border-red-300">
                  Know More
                </button>
              </div>
              <div className="w-full md:w-4/12 bg-white min-h-[300px] p-8 flex flex-col items-center gap-4 shadow-card">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services/engineering/redesign.png"
                  }
                  className=" h-[42px] w-[42px]"
                />
                <h1 className="text-2xl text-primary font-bold mt-2">
                  Design and Validation
                </h1>
                <p className="text-sm text-center text-[#5C5C5C]">
                  Division of Design and Validation involves in value
                  engineering, "Design and Validation" are critical phases aimed
                  at optimizing the design
                </p>
                <button className=" px-4 py-2  mt-4 rounded-sm border border-red-300">
                  Know More
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>

      <div
        className="py-16 min-h-screen  md:h-screen  bg-no-repeat bg-cover"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "/images/experties/bg.png"
          })`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
      >
        <div className="h-full w-full">
          <div className="container w-full h-full flex flex-wrap justify-center items-center py-4">
            <div className="w-full md:w-6/12 h-[400px]">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/services/engineering/robotic-systems/manufacturing.jpg"
                }
                className="w-auto h-[350px] "
              />
              {/* <Product /> */}
            </div>
            <div className="md:w-6/12 flex w-full justify-center item flex-wrap">
              <h1 className="text-4xl font-bold text-white">
                Manufacturing Solutions
              </h1>
              <p className="text-center text-white mt-6 font-extralight">
                At EMS, we understand that bringing your innovative ideas to
                life requires a reliable partner in the manufacturing and
                assembly process.
              </p>

              <button className=" px-4 py-2  mt-12 rounded-none text-white bg-[#CC7A00] hover text-white:bg-[#CC7A00]">
                Know More
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full min-h-screen flex flex-wrap justify-center items-center  ">
        <div className="container py-10 md:py-12">
          <div className="w-full flex justify-center">
            <h1 className="text-3xl md:text-4xl font-bold text-blue-600 ">
              Manufacturing and Assembly Services
            </h1>
          </div>
          <section className="w-full flex justify-center">
            <div className="w-full flex-wrap md:flex-nowrap flex justify-center items-center mt-24 gap-4 ">
              <div className="w-full md:w-4/12 bg-white min-h-[330px] p-8 flex flex-col items-center gap-4 shadow-card">
                <Boxes
                  className=" h-[42px] w-[42px] text-red-400"
                  strokeWidth={1}
                />
                <h1 className="text-2xl text-primary font-bold mt-2">
                  Product Development
                </h1>
                <p className="text-sm text-center text-[#5C5C5C]">
                  Our Product development and contract manufacturing refers to
                  the process of creating and refining a product in
                  collaboration with a customer manufacturing Requirement.
                </p>
                <button className=" px-4 py-2  mt-4 rounded-sm border border-red-300 ">
                  Know More
                </button>
              </div>
              <div className="w-full md:w-4/12 bg-white min-h-[330px] p-8 flex flex-col items-center gap-4 shadow-card">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/services/engineering/manufacture.png"
                  }
                  className=" h-[42px] w-[42px]"
                />
                <h1 className="text-2xl text-primary font-bold mt-2">
                  Fixtures and Equipments
                </h1>
                <p className="text-sm text-center text-[#5C5C5C]">
                  Our System integration team works closely with the customer to
                  build technology products which refers to the process of
                  combining various hardware and software components.
                </p>
                <button className=" px-4 py-2  mt-4 rounded-sm border border-red-300">
                  Know More
                </button>
              </div>
              <div className="w-full md:w-4/12 bg-white min-h-[330px] p-8 flex flex-col items-center gap-4 shadow-card">
                <Cpu
                  className=" h-[42px] w-[42px] text-red-400 "
                  strokeWidth={1}
                />
                <h1 className="text-2xl text-primary font-bold mt-2">
                  Technology Products
                </h1>
                <p className="text-sm text-center text-[#5C5C5C]">
                  Division of Design and Validation involves in value
                  engineering, "Design and Validation" are critical phases aimed
                  at optimizing the design
                </p>
                <button className=" px-4 py-2  mt-4 rounded-sm border border-red-300">
                  Know More
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Experties;
