import React from "react";
import type { StoryType } from "../../../types";

const Story: React.FC<StoryType> = ({
  background,
  description,
  href,
  title,
  className,
}) => {
  return (
    <div className="w-full md:w-5/12 bg-white shadow-card cursor-pointer ">
      <div className="relative aspect-video w-full flex-shrink-0 border border-light-300 bg-light-300 dark:border-0 dark:bg-dark-500 xs:w-32">
        <h1 className="font-sans font-extrabold absolute  text-white top-4 left-4 z-10">
          {title}
        </h1>
        <img alt={title} src={background} className="aspect-video  " />
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-30"></div>
      </div>
      <div>
        <p className="font-light p-4 text-gray-700 text-ellipsis text-sm text-justify ">
          {description}
        </p>
      </div>
    </div>
  );
};

const Stories = () => {
  const stories: StoryType[] = [
    {
      title: "Robotic Automation",
      background:
        "/images/services/engineering/robotic-systems/robot-application-gluing.jpg",
      description:
        "Industrial robots are used to automate repetitive and labor-intensive tasks within a manufacturing or processing line....",
      href: "link to what we do",
      className: "",
    },
    {
      title: "Flexible Manufacturing",
      background:
        "/images/services/engineering/robotic-systems/manufacturing.jpg",
      description:
        "Robotic systems in process engineering are designed to be highly flexible. They can be programmed and reprogrammed...",
      href: "link to what we do",
      className: "",
    },
    {
      title: "Integration with Manufacturing Systems",
      background: "/images/services/engineering/robotic-systems/cnc.jpg",
      description:
        "Robotic systems are often integrated with other manufacturing systems,such as computer numerical control (CNC) machines...",
      href: "link to what we do",
      className: "",
    },
    {
      title: "Sensors and Vision Systems",
      background: "/images/services/engineering/robotic-systems/sensor.jpg",
      description:
        "Many robotic systems are equipped with sensors and vision systems that allow them to perceive and respond to their environment...",
      href: "link to what we do",
      className: "",
    },
  ];
  return (
    <div className="full flex justify-center items-start gap-4 flex-wrap ">
      {stories.map((story, index) => (
        <Story {...story} key={index} />
      ))}
    </div>
  );
};

export default Stories;
