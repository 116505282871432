import React, { ReactNode } from "react";
import { Outlet, NavLink } from "react-router-dom";
import Nav from "./Nav";
import Footer from "./Footer";

interface Props {
  children: ReactNode;
}

const MainLayout: React.FC<{}> = () => {
  return (
    <>
      {/* <header>
        <nav className="w-full flex justify-center gap-4 h-[56px] items-center shadow-md fixed z-50 bg-white">
          <h1>EMS</h1>
          <NavLink to="/">Home</NavLink>
          <NavLink to="services">Services</NavLink>
          <NavLink to="about">About</NavLink>
          <NavLink to="contact">Contact</NavLink>
        </nav>
      </header> */}
      <Nav />
      <main className="overflow-hidden box">
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default MainLayout;
