import React from "react";

const EngineeringHero = () => {
  return (
    <section
      style={{
        backgroundImage: `url(${
          process.env.PUBLIC_URL + "/images/gray-triangles-bg.svg"
        })`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
      }}
      className="w-[100vw] h-[100vh]"
    >
      <div className="container flex w-full h-full justify-center items-center">
        <div className="w-full h-full md:w-6/12 flex flex-col items-start justify-center gap-10 ">
          <h1 className="text-5xl font-bold text-blue-600">
            Our Engineering Service
          </h1>
          <p className="text-gray-700 text-justify">
            Our startup spirit fuels our creative approach to engineering
            challenges. We excel in designing and implementing innovative
            engineering processes that are efficient, adaptable, and uniquely
            tailored to meet your specific needs.
          </p>
          <button className="bg-blue-600 px-4 py-2 text-white">Explore</button>
        </div>

        <div className="md:w-6/12"></div>
      </div>
    </section>
  );
};

export default EngineeringHero;
